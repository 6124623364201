import React from 'react'
import { Layout, Stack, Main, Hero, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogCategories } from '@helpers-blog'
import { Box, Container } from 'theme-ui'
import NewsletterExpanded from '@widgets/NewsletterExpanded'

const styles = {
  heroThumbsContainer: {
    left: `50%`,
    top: `50%`,
    position: `absolute`,
    transform: `translate(-50%, -50%)`,
    display: [`none`, null, null, `block`]
  },
  heroThumbsInner: {
    width: `1/3`,
    ml: `auto`
  }
}

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()
  const sliderRef = React.useRef()

  return (
    <Layout {...props}>
      <Seo title='Társasozz! blog' />

      <Hero full sx={{ position: `relative` }}>
        <CardList
          nodes={featuredPosts.nodes}
          limit={3}
          variant='horizontal-cover-hero'
          omitFooter
          autoplaySpeed={5000}
          slider
          autoPlay
          fade
          dots={false}
          arrows={false}
          ref={sliderRef}
          loading='eager'
        />
        {/* <Container sx={styles.heroThumbsContainer}>
          <Box sx={styles.heroThumbsInner}>
            <CardList
              nodes={featuredPosts.nodes}
              limit={3}
              variant='horizontal-aside'
              imageVariant='hero'
              omitCategory
              omitAuthor
              asNavFor={sliderRef}
              loading='eager'
            />
          </Box>
        </Container> */}
      </Hero>
      {/* <Divider />
      <Stack>
        <Main>
        <Categories
              categories={categories}
              variant='horizontal'
              omitTitle
            />
        </Main>
      </Stack> */}

<Divider />  

      <Stack
        title='Legújabb bejegyzéseink'
      >
        <Main>
          <CardList
            nodes={recentPosts.nodes}
            variant={['horizontal-md', 'vertical']}
            limit={6}
            columns={[1, 2, 3, 3]}
            omitExcerpt
          />
        </Main>
      </Stack>

      <Divider />   
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>

              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'vertical']}
                    limit={6}
                    columns={[1, 2, 3, 3]}
                    omitCategory
                  />
                </Main>
              </Stack>
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Divider space={5} />
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
